<template>
    <div class="about">
        <div class="page-head about">
            <h1 class="page-title font-weight-bold text-uppercase">
                {{ $t('aboutUs') }}
            </h1>
        </div>
        <div class="page-content">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 order-2 order-lg-1">
                        <h3 class="font-weight-600">{{ $t('aboutDLP') }}</h3>
                        <p class="text-color">
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                            professor at Hampden-Sydney College in Virginia,
                            looked up one of the more obscure Latin words,
                            consectetur, from a Lorem Ipsum passage, and going
                            through the cites of the word in classical
                            literature, discovered the undoubtable source. Lorem
                            Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                            Finibus Bonorum et Malorum" (The Extremes of Good
                            and Evil) by Cicero, written in 45 BC. This book is
                            a treatise on the theory of ethics, very popular
                            during the Renaissance. The first line of Lorem
                            Ipsum, "Lorem ipsum dolor sit amet..", comes from a
                            line in section 1.10.32.
                        </p>
                        <p class="text-color">
                            The standard chunk of Lorem Ipsum used since the
                            1500s is reproduced below for those interested.
                            Sections 1.10.32 and 1.10.33 from "de Finibus
                            Bonorum et Malorum" by Cicero are also reproduced in
                            their exact original form, accompanied by English
                            versions from the 1914 translation by H. Rackham.
                        </p>
                    </div>
                    <div
                        class="
                            col-lg-5
                            text-center
                            order-1 order-lg-2
                            mb-4 mb-lg-0
                        "
                    >
                        <img
                            class="mw-100"
                            src="@/assets/imgs/logo-lg.png"
                            alt="DLP"
                        />
                    </div>
                </div>
            </div>
        </div>
        <TopFooterAds />
    </div>
</template>

<script>
import TopFooterAds from "@/components/TopFooterAds";
export default {
    name: "About",
    components: {
        TopFooterAds,
    },
};
</script>
